import {BaseUser, Role} from '@core/model/auth/user.model';

export interface LoggedUser extends BaseUser {
  readonly fullName?: string;
  readonly role: LoggedUserRole;
}

export interface LoggedUserRole {
  name: Role;
  permissions: UserPermission[];
}

export interface UserPermission {
  readonly name: string;
}

export enum Permissions {
  canManageSelf = 'canManageSelf',
  // ADMIN
  canManageUsers = 'canManageUsers',

  canManageCapex = 'canManageCapex',

  canManageAlarm = 'canManageAlarm',

  canManageOpex = 'canManageOpex',

  canManageDocument = 'canManageDocument',

  canManageConfig = 'canManageConfig',

  // USER
}
